import "core-js/modules/es.array.concat.js";
import i18n from "@/lang/index";
var searchKeys = [{
  key: "parentids",
  placeholder: "".concat(i18n.t("common.sourceID"), "\uFF0C").concat(i18n.t("common.multipleRowsByEachID")),
  textarea: true,
  hidden: true
}, {
  key: "type",
  placeholder: i18n.t("common.type"),
  select: true
}, {
  key: "ids",
  placeholder: "".concat(i18n.t("comment.commentID"), "\uFF0C").concat(i18n.t("common.multipleRowsByEachID")),
  textarea: true,
  hidden: true
}, {
  key: "content",
  placeholder: i18n.t("common.keywordSearching"),
  input: true
}, {
  key: "status",
  placeholder: i18n.t("common.state"),
  select: true,
  initialValue: undefined
}, {
  key: "userids",
  placeholder: "".concat(i18n.t("comment.senderID"), "\uFF0C").concat(i18n.t("common.multipleRowsByEachID")),
  textarea: true,
  hidden: true
}, {
  key: "publish_time",
  placeholder: [i18n.t("comment.sendTimeBegin"), i18n.t("comment.sendTimeOver")],
  dates: true,
  attrs: {
    valueFormat: "YYYY-MM-DD HH:mm:ss"
  }
}, {
  key: "button",
  button: true
}];
var tableColumns = [{
  title: i18n.t("comment.commentID"),
  dataIndex: "id",
  key: "id",
  width: 140,
  fixed: "left",
  scopedSlots: {
    customRender: "id"
  }
},
// {
//   title: "来源类型",
//   dataIndex: "contenttype",
//   key: "contenttype",
//   width: 100,
//   scopedSlots: {
//     customRender: "contenttype",
//   },
// },
{
  title: i18n.t("comment.pin"),
  dataIndex: "istop",
  key: "istop",
  width: 80,
  scopedSlots: {
    customRender: "istop"
  }
}, {
  title: i18n.t("comment.sourceType"),
  dataIndex: "type",
  key: "type",
  width: 100,
  scopedSlots: {
    customRender: "type"
  }
}, {
  title: i18n.t("common.source"),
  dataIndex: "postid",
  key: "postid",
  width: 140,
  scopedSlots: {
    customRender: "postid"
  }
}, {
  title: i18n.t("comment.releaseTime"),
  dataIndex: "createtime",
  key: "createtime",
  width: 180,
  sorter: true,
  sortOrder: "descend"
}, {
  title: i18n.t("comment.rootComment"),
  dataIndex: "parentid",
  key: "parentid",
  width: 140,
  scopedSlots: {
    customRender: "parentid"
  }
}, {
  title: i18n.t("comment.commentContent"),
  dataIndex: "content",
  key: "content",
  width: 200,
  scopedSlots: {
    customRender: "content"
  }
}, {
  title: i18n.t("comment.likeCnts"),
  dataIndex: "liketotal",
  key: "liketotal",
  width: 90,
  scopedSlots: {
    customRender: "liketotal"
  }
}, {
  title: i18n.t("comment.senderName"),
  dataIndex: "username",
  key: "username",
  width: 160,
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: i18n.t("common.state"),
  dataIndex: "status",
  key: "status",
  width: 120,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: i18n.t("common.operation"),
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 250,
  scopedSlots: {
    customRender: "action"
  }
}];
var tableColumns2 = [{
  title: i18n.t("common.date"),
  dataIndex: "createtime",
  key: "createtime",
  width: 200
}, {
  title: i18n.t("common.remark"),
  dataIndex: "memo",
  key: "memo"
}];
export { searchKeys, tableColumns, tableColumns2 };